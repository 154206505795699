import * as React from "react";
import AudioProgressBar from "./AudioProgressBar";
import IconButton from "../player/IconButton";
import VolumeInput from "../player/VolumeInput";
import { MdPlayArrow, MdPause, MdSkipNext, MdVolumeOff, MdVolumeUp, MdSkipPrevious } from 'react-icons/md';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import { faPlayCircle, faPauseCircle, faForwardStep, faBackwardStep, faVolumeUp, faVolumeOff } from '@fortawesome/free-solid-svg-icons';
// import { LoadingSpinner } from "./Spinner";
import { formatDurationDisplay } from "../audio/util";
import { Trans  , useTranslation} from "gatsby-plugin-react-i18next";
import { useEffect } from "react";
import translates from '../../../locales/ar/index.json'

const useWindowSize = () => {
    const [windowSize, setWindowSize] = React.useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    React.useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
  
      // Set up event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // Empty dependency array means this effect runs once after the initial render
    
    return windowSize;
  };

export default function AudioPlayer(props) {
    const { currentSong, author, songCount, songIndex, onNext, onPrev, playing, setPlaying, setProgress } = props;
    //console.log(currentSong);
    var authorTranslate= obtenerTraduccion(author, window.location.href);
    var currentSongTranslate = obtenerTraduccion((currentSong?.trackName ?? "Select a track"), window.location.href);
    const audioRef = React.useRef();
    // states

    const [volume, setVolume] = React.useState(0.2); // set to 0.2, max is 1.0
    const handleVolumeChange = (volumeValue) => {
        if (!audioRef.current) return;
        audioRef.current.volume = volumeValue;
        setVolume(volumeValue);
    };
    const [duration, setDuration] = React.useState(0);
    const [isReady, setIsReady] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(playing);
    const {t} = useTranslation()
    const { width } = useWindowSize();
    function obtenerTraduccion(propiedad, url) {
        const locale = extractLocaleFromUrl(url);
        
        try {
            const translates = require(`../../../locales/${locale}/index.json`);
            const traduccion = translates[propiedad];
    
            if (traduccion) {
                return traduccion;
            }
        } catch (error) {
            console.error(`Error loading translations for locale: ${locale}`, error);
        }
    
        // If no translation found, return the original property
        return propiedad;
    }
    function extractLocaleFromUrl(url) {
        const match = url.match(/\/([a-z]{2})\//);
        return match ? match[1] : 'en'; // Default to 'en' if locale not found
    }
    React.useEffect(() => {
        if (playing !== isPlaying)
            togglePlayPause();
    }, [playing])

    React.useEffect(() => {
        audioRef?.current?.load();
    }, [currentSong]);

    React.useEffect(() => {
        setPlaying(isPlaying)
    }, [isPlaying]);
    // states
    const [currrentProgress, setCurrrentProgress] = React.useState(0);
    const [buffered, setBuffered] = React.useState(0);

    React.useEffect(() => {
        setProgress(currrentProgress);
    }, [currrentProgress])

    React.useEffect(() => {
        if (!audioRef.current) return
        if (audioRef.current) {
            console.log(audioRef.current);
            audioRef.current?.pause();
            const timeout = setTimeout(() => {
                audioRef.current?.play();
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        }

    }, [songIndex]);

    const durationDisplay = formatDurationDisplay(duration);
    const elapsedDisplay = formatDurationDisplay(currrentProgress);

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
                setIsPlaying(false);
            } else {
                audioRef.current.play();
                setIsPlaying(true);
            }
        }
    };
    const handleMuteUnmute = () => {
        if (!audioRef.current) return;
        if (audioRef.current.volume !== 0) {
            audioRef.current.volume = 0;
        } else {
            audioRef.current.volume = 1;
        }
    };

    // handler
    const handleBufferProgress = (e) => {
        const audio = e.currentTarget;
        const dur = audio.duration;
        if (dur > 0) {
            for (let i = 0; i < audio.buffered.length; i++) {
                if (
                    audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime
                ) {
                    const bufferedLength = audio.buffered.end(
                        audio.buffered.length - 1 - i,
                    );
                    setBuffered(bufferedLength);
                    break;
                }
            }
        }
    };
    return (
        <div className="sticky-player">
            <div className="progress-bar-container">
                <AudioProgressBar
                    duration={duration}
                    currentProgress={currrentProgress}
                    buffered={buffered}
                    onChange={(e) => {
                        if (!audioRef.current) return;
                        audioRef.current.currentTime = e.currentTarget.valueAsNumber;
                        setCurrrentProgress(e.currentTarget.valueAsNumber);
                    }}
                />
                <audio
                    ref={audioRef}
                    preload="metadata"
                    onDurationChange={(e) => setDuration(e.currentTarget.duration)}
                    onEnded={onNext}
                    onCanPlay={(e) => {
                        e.currentTarget.volume = volume;
                        setIsReady(true);
                    }}
                    onTimeUpdate={(e) => {
                        setCurrrentProgress(e.currentTarget.currentTime);
                        handleBufferProgress(e);
                    }}
                    onPlaying={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
                    onProgress={handleBufferProgress}
                >
                    <source type="audio/mpeg" src={currentSong?.downloadLink} />
                </audio>
            </div>
            <div className="player-controls-section">
                <div className="player-controls-section--wrapper">
                    <div className="controls-track-meta">
                        <div className="controls-track-meta--title">{currentSongTranslate}</div>
                        <div className="controls-track-meta--artist">{authorTranslate}</div>
                    </div>
                    <div className="controls-main">
                        <div className="controls-main--wrapper">
                            {
                                songIndex === 0 ? 
                                <FontAwesomeIcon icon={faBackwardStep} className="icon-backward-disabled" />
                                :
                                <FontAwesomeIcon icon={faBackwardStep} className="icon-backward" onClick={onPrev} />
                            }
                            {!isReady && currentSong ? (
                                // <LoadingSpinner size={24} className="animate-spin" />
                                <FontAwesomeIcon icon={faPauseCircle} className="icon-circle-disabled" />

                            ) : isPlaying ? (
                                <FontAwesomeIcon icon={faPauseCircle} className="icon-circle" onClick={togglePlayPause}/>
                            ) : (
                                <FontAwesomeIcon icon={faPlayCircle} className="icon-circle" onClick={togglePlayPause} />
                            )}
                            {
                                songIndex === songCount - 1 ?
                                <FontAwesomeIcon icon={faForwardStep} className="icon-forward-disabled" />
                                :
                                <FontAwesomeIcon icon={faForwardStep} className="icon-forward" onClick={onNext} />
                            }
                            
                        </div>
                    </div>
                    <div className="controls-right-section">
                        <div className="controls-right-section--timer">
                            <div className="controls-right-section--timer-progress">{elapsedDisplay}</div>
                            <div className="controls-right-section--timer-separator">/</div>
                            <div className="controls-right-section--timer-duration">{durationDisplay}</div>
                        </div>
                        { width > 600 && <div className="controls-right-section--right">
                            {volume === 0 ? <FontAwesomeIcon icon={faVolumeOff} className="icon-volume" onClick={handleMuteUnmute}/> : <FontAwesomeIcon icon={faVolumeUp} className="icon-volume" onClick={handleMuteUnmute}/>}
                            <p>
                                <VolumeInput volume={volume} onVolumeChange={handleVolumeChange} />
                            </p>
                        </div> }
                    </div>
                </div>
            </div>
        </div>
    );
}
