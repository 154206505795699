import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import AudioContextProvider from "./src/util/audioPlayerProvider";
require("@fortawesome/fontawesome-svg-core/styles.css");

ReactGA.initialize("G-PKPSTM5Q4G");

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = createRoot(container);
    root.render(element);
  };
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  function CustomThrottle(func, limit) {
    var lastFunc, lastRan;
    return function () {
      var context = this,
        args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  }

  var navType = document.querySelector(".site-wrap")
    ? document.querySelector(".site-wrap").getAttribute("data-nav")
    : "";
  var header = document.querySelector(".site-header");
  if (
    typeof header !== "undefined" &&
    header !== null &&
    navType === "sticky"
  ) {
    window.addEventListener(
      "scroll",
      CustomThrottle(function () {
        var currScroll = window.pageYOffset;
        if (currScroll > 1) {
          header.classList.add("small");
        } else {
          header.classList.remove("small");
        }
      }, 50)
    );
  }

  var tables = document.querySelectorAll("table");
  if (tables.length > 0) {
    tables.forEach(function (table) {
      var wrapper = document.createElement("div");
      wrapper.classList.add("table-responsive");
      table.parentNode.insertBefore(wrapper, table);
      wrapper.appendChild(table);
    });
  }

  // Track pageviews with GA4
  ReactGA.event({
    category: "Pageview",
    action: "View",
    label: location.pathname,
  });
};

export const wrapRootElement = ({ element, props }) => {
  return <AudioContextProvider {...props}>{element}</AudioContextProvider>;
};
