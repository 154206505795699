import * as React from "react";

export default function AudioProgressBar(props) {
    const { duration, currentProgress, buffered, ...rest } = props;
    const progressBarWidth = isNaN(currentProgress / duration)
        ? 0
        : currentProgress / duration;
    const bufferedWidth = isNaN(buffered / duration) ? 0 : buffered / duration;
    const progressStyles = {
        "--progress-width": progressBarWidth,
        "--buffered-width": bufferedWidth,
    };

    const handleDivClick = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const divWidth = rect.width;
        const calculatedPercentage = (mouseX / divWidth) * 100;
    
        if (props.onChange) {
            const e = {
                currentTarget: {
                    valueAsNumber: calculatedPercentage * duration / 100
                }
            }
            props.onChange(e);
        }
      };

    return (
        <div className="progress-bar-total" onClick={handleDivClick}>
            <div className="progress-bar" style={{ width: `${currentProgress / duration * 100}%`}}>
            </div>
        </div>
    );
}