export const playAudio = (isPlaying, audioRef) => {
  if (isPlaying) {
    const playPromise = audioRef.current.play()
    if (playPromise !== null) {
      playPromise.then(audio => {
        audioRef.current.play()
      })
    }
  }
}

export const formatDurationDisplay = (duration) => {
  const hour = Math.floor(duration / 3600);
  const min = Math.floor((duration - hour * 3600) / 60);
  const sec = Math.floor(duration - min * 60 - hour * 3600);
  let formatted = [min, sec].map((n) => (n < 10 ? "0" + n : n)).join(":"); // format - mm:ss
  if (hour > 0) {
    formatted = (hour < 10 ? "0" + hour : hour) + ":" + formatted;
  }
  return formatted;
}